import { UserInteraction } from './userInteraction.model'

export class UserInteractionSearch {
    interactions?: UserInteraction[]
    searchTerm: string

    constructor() {
        this.interactions = undefined
        this.searchTerm = ''
    }
}
