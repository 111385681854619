
















import Vue from 'vue'
import ParticipationList from './ParticipationList.vue'

export default Vue.extend({
    name: 'ParticipationSearch',
    components: {
        ParticipationList,
    },
    data() {
        return {
            campaignKey: '',
            timeout: undefined,
            previousSearchTerm: '',
        }
    },
    mounted() {
        this.campaignKey = this.$route.params.campaignKey
    },
    methods: {
        triggerSearch: function(searchParam: string) {
            if (searchParam !== this.previousSearchTerm) {
                this.previousSearchTerm = searchParam
                this.$store.dispatch('searchForParticipation', {
                    campaignKey: this.campaignKey,
                    searchTerm: searchParam,
                })
            }
        },
        searchChanged: function(event: CustomEvent<string>) {
            if (this.timeout) clearTimeout(this.timeout)
            // @ts-ignore
            this.timeout = setTimeout(() => {
                this.triggerSearch(event.detail)
            }, 500)
        },
    },
})
