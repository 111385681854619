




































import Vue from 'vue'

export default Vue.extend({
    name: 'LeaderboardRewardsComponent',
    data: function() {
        return {
            numberValidation: {
                regex: '^[1-9]{1}[0-9]*$',
                errorMessage: 'Invalid number',
            },
            id: undefined,
            from: undefined,
            to: undefined,
        }
    },
    props: ['campaignKey', 'promotionKey'],
    methods: {
        rewardIdChanged: function(event: CustomEvent) {
            this.id = event.detail == '' ? undefined : event.detail
        },
        rewardToChanged: function(event: CustomEvent) {
            this.to = event.detail == '' ? undefined : event.detail
        },
        rewardFromChanged: function(event: CustomEvent) {
            this.from = event.detail == '' ? undefined : event.detail
        },
        sendRewards() {
            var body = {
                campaignKey: this.campaignKey,
                promotionKey: this.promotionKey,
                skuId: this.id,
                rankFrom: this.from,
                rankTo: this.to,
            }
            this.$store.dispatch('triggerRewardCeremony', body)
        },
    },
})
