






































import Vue from 'vue'
import CampaignList from './CampaignList.vue'
import ManagementUserList from './ManagementUserList.vue'
import UserInteractionSearch from './UserInteractionSearch.vue'
import { UserInfo } from '@/models/userInfo.model'

export default Vue.extend({
    name: 'OverviewTabs',
    components: {
        UserInteractionSearch,
        CampaignList,
        ManagementUserList,
    },
    computed: {
        activeTabInSession() {
            const activeOverviewTab = this.$store.getters.getActiveOverviewTab
            return !activeOverviewTab || activeOverviewTab.length === 0
                ? 'id1'
                : activeOverviewTab
        },
        createCampaign() {
            return '/campaign_create'
        },
        addUserLink() {
            return '/user_create'
        },
        isAdmin() {
            const userInfo: UserInfo = this.$store.getters.getUserInfo
            return userInfo.isAdmin
        },
    },
    methods: {
        tabSelect: function(event: CustomEvent<string>) {
            this.$store.commit('setActiveOverviewTab', event.detail)
        },
    },
})
