






















import Vue from 'vue'
import CampaignItem from './CampaignItem.vue'
import { Campaign } from '@/models/campaign.model'

export default Vue.extend({
    name: 'CampaignList',
    components: {
        CampaignItem,
    },
    mounted() {
        this.$store.dispatch('retrieveCampaigns')
    },
    methods: {
        refreshCampaigns() {
            this.$store.dispatch('fetchCampaigns')
        },
    },
    computed: {
        isLoading(): boolean {
            return this.$store.state.data.campaigns.isLoading
        },
        campaignList(): Campaign[] {
            return this.$store.state.data.campaigns.list
        },
    },
})
