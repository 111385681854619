












































































import Vue from 'vue'
import { createPromotion } from '@/models/createPromotion.model'
import { CampaignStatistics } from '../models/campaignStatistics.model'

export default Vue.extend({
    name: 'ManagementPromotionCreator',
    data() {
        return {
            overviewLink: '/',
            promotionKey: '',
            name: '',
            description: '',
            campaignKey: '',
            maxRedemptionsPerUser: 1,
            validFrom: '',
            validUntil: '',
            scope: '',
            isError: false,
            errorMsg: '',
        }
    },
    mounted() {
        this.campaignKey = this.$route.params.campaignKey
    },
    methods: {
        savePromotion() {
            this.isError = false
            if (!this.name || 0 === this.name.length) {
                this.errorMsg = 'Name is required'
                this.isError = true
                return
            }
            if (!this.promotionKey || 0 === this.promotionKey.length) {
                this.errorMsg = 'PromotionKey is required'
                this.isError = true
                return
            }
            const alreadyExisting = this.promotionStats.filter(
                value => this.promotionKey === value.promotionKey
            )
            if (alreadyExisting.length != 0) {
                this.errorMsg =
                    'PromotionKey "' + this.promotionKey + '" already exists'
                this.isError = true
                return
            }
            if (!this.description || 0 === this.description.length) {
                this.errorMsg = 'Description is required'
                this.isError = true
                return
            }
            if (!this.scope || 0 === this.scope.length) {
                this.errorMsg = 'Scope is required'
                this.isError = true
                return
            }
            this.$store
                .dispatch(
                    'createPromotion',
                    new createPromotion(
                        this.promotionKey,
                        this.name,
                        this.description,
                        this.campaignKey,
                        this.maxRedemptionsPerUser,
                        this.validFrom,
                        this.validUntil
                    )
                )
                .then(() => {
                    this.$store.commit('setInfo', 'Created Promotion')
                    this.goBack()
                    this.$store.dispatch(
                        'fetchStatsForCampaign',
                        this.campaignKey
                    )
                })
        },
        nameChange: function(event: CustomEvent<string>) {
            this.name = event.detail
        },
        keyChange: function(event: CustomEvent<string>) {
            this.promotionKey = event.detail
        },
        descriptionChange: function(event: CustomEvent<string>) {
            this.description = event.detail
        },
        maxRedemptionsChange: function(event: CustomEvent<number>) {
            this.maxRedemptionsPerUser = event.detail
        },
        onScopeSelected: function(event: CustomEvent<string>) {
            this.scope = event.detail
            const tmp = this.scope.split('/', 2)
            this.validFrom = tmp[0]
            this.validUntil = tmp[1]
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
        },
    },
    computed: {
        campaignStatistics() {
            return this.$store.getters.getStatisticsForCampaign(
                this.$data.campaignKey
            )
        },
        promotionStats() {
            const campaign = this.campaignStatistics as CampaignStatistics
            return campaign.promotionStats
        },
    },
})
