









import Vue from 'vue'
import TopBar from './components/TopBar.vue'
import ErrorBar from './components/ErrorBar.vue'
import InfoBar from './components/InfoBar.vue'

export default Vue.extend({
    components: {
        TopBar,
        ErrorBar,
        InfoBar,
    },
    computed: {
        isInError(): boolean {
            return this.$store.getters.isInError
        },
        hasInfo(): boolean {
            return this.$store.getters.hasInfo
        },
    },
})
