







































































































import Vue from 'vue'
import { createCampaign } from '@/models/createCampaign.model'
import { Campaign } from '@/models/campaign.model'

export default Vue.extend({
    name: 'ManagementCampaignCreator',
    data() {
        return {
            overviewLink: '/',
            name: '',
            campaignKey: '',
            description: '',
            providerName: '',
            ipLimitEnabled: false,
            rateLimitEnabled: false,
            recaptchaEnabled: false,
            externalConfirmationRequired: false,
            redemptionType: '',
            isError: false,
            errorMsg: '',
        }
    },
    methods: {
        saveCampaign() {
            this.isError = false
            if (!this.name || 0 === this.name.length) {
                this.errorMsg = 'Name is required'
                this.isError = true
                return
            }
            if (!this.campaignKey || 0 === this.campaignKey.length) {
                this.errorMsg = 'CampaignKey is required'
                this.isError = true
                return
            }
            if (!this.redemptionType || 0 === this.redemptionType.length) {
                this.errorMsg = 'Redemption Type is required'
                this.isError = true
                return
            }
            const alreadyExisting = this.campaignList().filter(
                value => this.campaignKey === value.campaignKey
            )
            if (alreadyExisting.length != 0) {
                this.errorMsg =
                    'CampaignKey "' + this.campaignKey + '" already exists'
                this.isError = true
                return
            }
            if (!this.description || 0 === this.description.length) {
                this.errorMsg = 'Description is required'
                this.isError = true
                return
            }
            this.$store
                .dispatch(
                    'createCampaign',
                    new createCampaign(
                        this.campaignKey,
                        this.name,
                        this.description,
                        this.providerName,
                        this.ipLimitEnabled,
                        this.rateLimitEnabled,
                        this.recaptchaEnabled,
                        this.externalConfirmationRequired,
                        this.redemptionType
                    )
                )
                .then(() => {
                    this.$router.push('/')
                    this.$store.commit('setInfo', 'Created Campaign')
                    this.$store.dispatch('fetchCampaigns')
                })
        },
        nameChange: function(event: CustomEvent<string>) {
            this.name = event.detail
        },
        keyChange: function(event: CustomEvent<string>) {
            this.campaignKey = event.detail
        },
        descriptionChange: function(event: CustomEvent<string>) {
            this.description = event.detail
        },
        providerChange: function(event: CustomEvent<string>) {
            this.providerName = event.detail
        },
        campaignList(): Campaign[] {
            return this.$store.state.data.campaigns.list
        },
    },
})
