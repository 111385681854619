
































import Vue from 'vue'
import ParticipationSearch from '@/components/ParticipationSearch.vue'
import CampaignStatistics from '@/components/CampaignStatistics.vue'

export default Vue.extend({
    name: 'CampaignTabs',
    components: {
        ParticipationSearch,
        CampaignStatistics,
    },
    mounted() {
        var campaignKey = this.$route.params.campaignKey as string
        this.$store.dispatch('retrieveStatsForCampaign', campaignKey)
    },
    computed: {
        activeTabInSession() {
            const activeOverviewTab = this.$store.getters.getActiveOverviewTab
            return !activeOverviewTab || activeOverviewTab.length === 0
                ? 'id1'
                : activeOverviewTab
        },
        createPromotion() {
            return `/promotion_create/${this.$route.params.campaignKey}`
        },
        campaignCodeUpload() {
            return `/code_upload/${this.$route.params.campaignKey}`
        },
        isCodeCampaign() {
            const campaign = this.$store.getters.getCampaignByKey(
                this.$route.params.campaignKey
            )
            return (
                campaign != null &&
                `RECEIPT` != campaign.supportedRedemptionType
            )
        },
    },
    data() {
        return {
            campaignKey: '',
        }
    },
    methods: {
        tabSelect: function(event: CustomEvent<string>) {
            this.$store.commit('setActiveOverviewTab', event.detail)
        },
    },
})
