





























































import Vue from 'vue'
import { PromotionStatistics } from '@/models/promotionStatistics.model'

export default Vue.extend({
    name: 'PromotionDetailsOverviewComponent',
    props: [
        'campaignKey',
        'stats',
        'hasLeaderboard',
        'leaderBoardParticipants',
    ],
    methods: {
        campaignLink() {
            const ck = this.campaignKey
            return `/campaign/${ck}`
        },
        promotionState() {
            const stats = this.stats as PromotionStatistics
            const now = Date.now()
            return (
                Date.parse(stats.validFrom) < now &&
                now < Date.parse(stats.validUntil)
            )
        },
    },
})
