export namespace ROUTING {
    export const DEFAULT_PATH = '/overview'
    export const ROUTE_STORAGE_KEY = 'destination'
}

export namespace AUTH_STORAGE {
    export const ACCESS_TOKEN: string = 'access_token'
    export const EXPIRES_AT: string = 'expires_at'
    export const AUTH_ZERO_LOADING: string = 'auth_in_progress'
}
