import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '../store'

export const infoGetters: GetterTree<RootState, RootState> = {
    hasInfo: (state: RootState) => !!state.info.latest,
}

export const infoMutations: MutationTree<RootState> = {
    setInfo: (state: RootState, latest) => {
        state.info.latest = latest
    },
}

export const infoActions: ActionTree<RootState, RootState> = {
    clearInfo: ({ commit }) => {
        setTimeout(() => {
            commit('setInfo', undefined)
        }, 5000)
    },
}
