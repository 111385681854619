import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '../store'

export const errGetters: GetterTree<RootState, RootState> = {
    isInError: (state: RootState) => !!state.err.latest,
}

export const errMutations: MutationTree<RootState> = {
    setError: (state: RootState, latest) => {
        state.err.latest = latest
    },
}

export const errActions: ActionTree<RootState, RootState> = {
    clearError: ({ commit }) => {
        setTimeout(() => {
            commit('setError', undefined)
        }, 5000)
    },
}
