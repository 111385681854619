import Vue from 'vue'
import Router, { RouterOptions } from 'vue-router'
import AuthCallback from '@/components/AuthCallback.vue'
import CampaignTabs from '@/components/CampaignDetailsTabs.vue'
import OverviewTabs from '@/components/OverviewTabs.vue'
import NotFound from '@/components/NotFound.vue'
import { ROUTING } from '@/commons/constants'
import { appStore } from '@/state/store'
import ManagementUserCreator from '@/components/ManagementUserCreator.vue'
import ManagementUserEditor from '@/components/ManagementUserEditor.vue'
import ManagementCampaignCreator from '@/components/ManagementCampaignCreator.vue'
import ManagementPromotionCreator from '@/components/ManagementPromotionCreator.vue'
import ManagementPromotionUpdater from '@/components/ManagementPromotionUpdater.vue'
import ManagementPromotionDetails from '@/components/ManagementPromotionDetails.vue'
import ManagementCodeUpload from '@/components/ManagementCodeUpload.vue'
import LogoutPage from '@/components/LogoutPage.vue'
import { WebAuthOperations } from '@/commons/auth.utils'

Vue.use(Router)
const AUTH_CALLBACK = '/'

const securedPageGuard = (to: any, from: any, next: any) => {
    if (to.path === '/logout') {
        next()
        return
    }

    const validTokens = WebAuthOperations.isUserAuthenticated(appStore.state)
    if (to.path !== AUTH_CALLBACK && !validTokens) {
        next({ path: AUTH_CALLBACK, query: { redirectTo: to.fullPath } })
        return
    }
    if (to.path == AUTH_CALLBACK && validTokens) {
        to.query.redirectTo = ROUTING.DEFAULT_PATH
        next()
        return
    }
    next()
}

const routerConfig: RouterOptions = {
    mode: 'history',
    routes: [
        {
            path: '/overview',
            component: OverviewTabs,
        },
        {
            path: AUTH_CALLBACK,
            component: AuthCallback,
        },
        {
            path: '/logout',
            component: LogoutPage,
        },
        {
            path: '/campaign/:campaignKey',
            component: CampaignTabs,
        },
        {
            path: '*',
            component: NotFound,
        },
        {
            path: '/user_create',
            component: ManagementUserCreator,
        },
        {
            path: '/user/:userId',
            component: ManagementUserEditor,
        },
        {
            path: '/campaign_create',
            component: ManagementCampaignCreator,
        },
        {
            path: '/promotion_create/:campaignKey',
            component: ManagementPromotionCreator,
        },
        {
            path: '/promotion_update/:campaignKey/:promotionKey',
            component: ManagementPromotionUpdater,
        },
        {
            path: '/promotion_details/:campaignKey/:promotionKey',
            component: ManagementPromotionDetails,
        },
        {
            path: '/code_upload/:campaignKey',
            component: ManagementCodeUpload,
        },
    ],
}

const router: Router = new Router(routerConfig)
router.beforeEach((to: any, from: any, next: any) =>
    securedPageGuard(to, from, next)
)

export { router }
