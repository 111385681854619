
























































import Vue from 'vue'
import LeaderboardRewardsComponent from '@/components/leaderboard/LeaderboardRewardsComponent.vue'
import LeaderboardBrazeComponent from '@/components/leaderboard/LeaderboardBrazeComponent.vue'
import PromotionDetailsOverviewComponent from '@/components/promotion/PromotionDetailsOverviewComponent.vue'
import LeaderboardRankingsComponent from '@/components/leaderboard/LeaderboardRankingsComponent.vue'
import RedemptionsExportComponent from '@/components/redemptions/RedemptionsExportComponent.vue'

export default Vue.extend({
    name: 'ManagementPromotionDetails',
    components: {
        LeaderboardRewardsComponent,
        LeaderboardBrazeComponent,
        PromotionDetailsOverviewComponent,
        LeaderboardRankingsComponent,
        RedemptionsExportComponent,
    },
    data: function() {
        return {
            campaignKey: '',
            promotionKey: '',
            leaderboardActive: false,
        }
    },
    mounted() {
        this.$data.campaignKey = this.$route.params.campaignKey
        this.$data.promotionKey = this.$route.params.promotionKey

        this.$store.dispatch('retrieveStatsForCampaign', this.$data.campaignKey)
        this.$store.dispatch('fetchLeaderboardStatistics', {
            campaignKey: this.$data.campaignKey,
            promotionKey: this.$data.promotionKey,
        })
    },
    methods: {
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
        },
    },
    computed: {
        numberOfParticipants() {
            return this.$store.getters.getParticipantCount
        },
        isLoading() {
            return this.$store.getters.isLeaderboardLoading
        },
        hasLeaderboardToShow() {
            return this.$store.getters.existsLeaderboard(
                this.$data.campaignKey,
                this.$data.promotionKey
            )
        },
        getCampaignKey() {
            return this.$data.campaignKey
        },
        getPromotionKey() {
            return this.$data.promotionKey
        },
        getPromotionStats() {
            const ps = this.$store.getters.getStatisticsForPromotion(
                this.$data.campaignKey,
                this.$data.promotionKey
            )
            if (ps != null) {
                return ps
            }
            return {}
        },
    },
})
