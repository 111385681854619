import { Campaign } from '@/models/campaign.model'
import { UserInteractionSearch } from '@/models/userInteractionSearch'
import { ManagementUser } from '@/models/managementuser.model'
import { UserInfo } from '@/models/userInfo.model'

export class DataState {
    public campaigns: Campaigns
    public userInteractionSearch: UserInteractionSearch
    public managementUsers: Users
    public activeOverviewTab: string
    public userInfo: UserInfo

    constructor() {
        this.campaigns = { list: undefined, isLoading: false }
        this.userInteractionSearch = new UserInteractionSearch()
        this.managementUsers = { list: undefined, isLoading: false }
        this.activeOverviewTab = ''
        this.userInfo = new UserInfo(false, false)
    }
}

export interface Users {
    list?: ManagementUser[]
    isLoading: boolean
}

export interface Campaigns {
    list?: Campaign[]
    isLoading: boolean
}
