













import Vue from 'vue'
import { Campaign } from '@/models/campaign.model'

export default Vue.extend({
    props: {
        campaign: Object as () => Campaign,
    },
    computed: {
        campaignHeading() {
            const campaign = this.campaign as Campaign
            return `${campaign.name} (${campaign.campaignKey})`
        },
        detailsLink() {
            const campaign = this.campaign as Campaign
            return `/campaign/${campaign.campaignKey}`
        },
    },
})
