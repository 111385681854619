

































import JsonCSV from 'vue-json-csv'
import Vue from 'vue'

Vue.component('downloadCsv', JsonCSV)

export default Vue.extend({
    name: 'LeaderboardRankingsComponent',
    data: function() {
        return {
            limit: 10,
            entries: undefined,
            defaultColumns: [
                {
                    title: 'Rank',
                    field: 'rank',
                },
                {
                    title: 'UserId',
                    field: 'user',
                },
                {
                    title: 'Score',
                    field: 'score',
                },
            ],
            riotColumns: [
                {
                    title: 'Rank',
                    field: 'rank',
                },
                {
                    title: 'UserId',
                    field: 'user',
                },
                {
                    title: 'Score',
                    field: 'score',
                },
                {
                    title: 'Summoner Name',
                    field: 'summonerName',
                },
                {
                    title: 'Summoner ID',
                    field: 'summonerId',
                },
                {
                    title: 'Region',
                    field: 'region',
                },
            ],
        }
    },
    props: ['campaignKey', 'promotionKey'],
    methods: {
        refreshRankings: function() {
            this.$store
                .dispatch('fetchPromotionRankings', {
                    campaignKey: this.campaignKey,
                    promotionKey: this.promotionKey,
                    limit: this.limit,
                })
                .then(result => (this.entries = result))
        },
        csvFileName: function(): String {
            // @ts-ignore
            return (
                'rankings-' +
                this.campaignKey +
                '-' +
                this.promotionKey +
                '-' +
                Date.now() +
                '.csv'
            )
        },
        limitChanged: function(event: CustomEvent<number>) {
            this.limit = event.detail
        },
    },
    computed: {
        selectColumns() {
            if (
                this.$data.entries === undefined ||
                this.$data.entries[0].summonerId === undefined
            ) {
                return this.$data.defaultColumns
            } else {
                return this.$data.riotColumns
            }
        },
    },
})
