

















































































































































// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import Vue from 'vue'

Vue.use(Loading)

export default Vue.extend({
    name: 'ManagementCodeUpload',
    data() {
        return {
            campaignKey: '',
            codeFile: undefined,
            countryCode: 'ZZ',
            isError: false,
            errorMsg: '',
            countryValidation: {
                regex: '^[A-Z]{2}$',
                errorMessage: 'Invalid ISO CountryCode',
            },
            metadataValidation: {
                regex: '.',
                errorMessage: 'Invalid Metadata',
            },
            codeValidation: {
                regex: '^[a-zA-Z0-9äÄöÖüÜ\\-\\.]{3,100}$',
                errorMessage: 'Invalid Code pattern.',
            },
            singleCode: {
                code: '',
                country: 'ZZ',
                metadata: '',
            },
            selectedSub: '',
        }
    },
    mounted() {
        this.campaignKey = this.$route.params.campaignKey
        this.$store.dispatch('retrieveStatsForCampaign', this.campaignKey)
    },
    methods: {
        updateFile() {
            // @ts-ignore
            if (this.$refs.fileInput.files[0].size > 2000000) {
                this.$store.commit(
                    'setError',
                    'You are trying to upload too many codes, please contact a "Code Redemption Admin"'
                )
                this.codeFile = undefined
            } else {
                // @ts-ignore
                this.codeFile = this.$refs.fileInput.files[0]
            }
        },
        uploadCodes() {
            let loader = this.$loading.show({
                isFullPage: true,
                canCancel: false,
            })

            let dto = {
                codeFile: this.codeFile,
                country: this.countryCode,
                campaignKey: this.campaignKey,
            }
            this.$store
                .dispatch('uploadCodeFile', dto)
                .then(() => {
                    this.$store.dispatch(
                        'fetchStatsForCampaign',
                        this.campaignKey
                    )
                    this.goBack()
                })
                .finally(() => {
                    loader.hide()
                })
        },
        singleCodeChanged: function(event: CustomEvent<string>) {
            this.singleCode.code = event.detail
        },
        singleMetadataChanged: function(event: CustomEvent<string>) {
            this.singleCode.metadata = event.detail
        },
        singleCountryChanged: function(event: CustomEvent<string>) {
            this.singleCode.country = event.detail
        },
        addCode() {
            let loader = this.$loading.show({
                isFullPage: true,
                canCancel: false,
            })
            let dto = {
                code: this.singleCode.code,
                metadata: this.singleCode.metadata,
                country: this.singleCode.country,
                campaignKey: this.campaignKey,
            }
            this.$store
                .dispatch('addSingeCodeToCampaign', dto)
                .then(() => {
                    this.$store.dispatch(
                        'fetchStatsForCampaign',
                        this.campaignKey
                    )
                    this.goBack()
                })
                .finally(() => {
                    loader.hide()
                })
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
        },
        showSingleCodeUpload() {
            this.selectedSub = 'SINGLE'
        },
        showFileUpload() {
            this.selectedSub = 'FILE'
        },
    },
    computed: {
        campaignStatistics() {
            return this.$store.getters.getStatisticsForCampaign(
                this.$data.campaignKey
            )
        },
        isSingleCodeUploadSelected() {
            return this.$data.selectedSub == 'SINGLE'
        },
        isFileUploadSelected() {
            return this.$data.selectedSub == 'FILE'
        },
    },
})
