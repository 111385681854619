




















































































import Vue from 'vue'
import { Campaign } from '../models/campaign.model'
import PromotionStatistics from './PromotionStatistics.vue'
import { CampaignStatistics } from '../models/campaignStatistics.model'

export default Vue.extend({
    name: 'CampaignStatistics',
    components: {
        PromotionStatistics,
    },
    data() {
        return {
            campaignKey: '',
            overviewLink: '/',
        }
    },
    mounted() {
        this.campaignKey = this.$route.params.campaignKey as string
    },
    methods: {
        refreshStats() {
            this.$store.dispatch('fetchStatsForCampaign', this.campaignKey)
        },
    },
    computed: {
        isLoading(): boolean {
            const campaign = this.campaignStatistics as CampaignStatistics
            return campaign === undefined
        },
        redemptionCount(): number {
            const campaign = this.campaignStatistics as CampaignStatistics
            return campaign.redemptionCount
        },
        campaign() {
            return this.$store.getters.getCampaignByKey(this.$data.campaignKey)
        },
        campaignStatistics() {
            return this.$store.getters.getStatisticsForCampaign(
                this.$data.campaignKey
            )
        },
        campaignName() {
            let campaign = this.campaign as Campaign
            return campaign ? campaign.name : 'Loading...'
        },
        isCodeCampaign() {
            const campaign = this.$store.getters.getCampaignByKey(
                this.$route.params.campaignKey
            )
            return campaign.supportedRedemptionType != `RECEIPT`
        },
    },
})
