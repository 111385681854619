import { WebAuthOperations } from '@/commons/auth.utils'
import { StorageUtils } from '@/commons/storage.utils'

export class AuthState {
    public expiresAt: number
    public accessToken?: string
    public authActionImpending: boolean

    constructor(storageProvider = StorageUtils, public headers?: AuthHeaders) {
        this.expiresAt = storageProvider.getAuthAccessTokenExpiresAt()
        this.accessToken = storageProvider.getAuthAccessToken()
        this.authActionImpending =
            storageProvider.isAuthLoading() ||
            WebAuthOperations.checkValidityFor(this.expiresAt, this.accessToken)
    }
}
export interface AuthHeaders {
    Authorization: String
}
