import { AuthHeaders } from '@/state/auth/auth.state'

export namespace HeaderUtils {
    export const constructHeadersMap = (
        headers: AuthHeaders | undefined
    ): Record<string, string> => {
        const headersMap: Record<string, string> = {}
        if (!headers) {
            return headersMap
        }
        headersMap['Authorization'] = headers.Authorization.valueOf()
        return headersMap
    }
}
