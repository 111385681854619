





























import Vue from 'vue'
import ManagementUserItem from './ManagementUserItem.vue'
import { ManagementUser } from '@/models/managementuser.model'

export default Vue.extend({
    name: 'ManagementUserList',
    components: {
        ManagementUserItem,
    },
    mounted() {
        this.$store.dispatch('fetchManagementUsers')
    },
    methods: {
        refreshUsers() {
            this.$store.dispatch('fetchManagementUsers')
        },
    },
    computed: {
        isLoading(): boolean {
            return this.$store.state.data.managementUsers.isLoading
        },
        userList(): ManagementUser[] {
            return this.$store.state.data.managementUsers.list
        },
        noUserFound(): boolean {
            return this.userList && this.userList.length === 0
        },
    },
})
