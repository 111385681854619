












import Vue from 'vue'
import { Participation } from '@/models/participation.model'

export default Vue.extend({
    name: 'ParticipationItem',
    props: {
        participation: Object as () => Participation,
    },
})
