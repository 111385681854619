export class createCampaign {
    campaignKey: string
    name: string
    description: string
    providerName: string
    ipLimitEnabled: boolean
    rateLimitEnabled: boolean
    recaptchaEnabled: boolean
    externalConfirmationRequired: boolean
    supportedRedemptionType: string

    constructor(
        campaignKey: string,
        name: string,
        description: string,
        providerName: string,
        ipLimitEnabled: boolean,
        rateLimitEnabled: boolean,
        recaptchaEnabled: boolean,
        externalConfirmationRequired: boolean,
        redemptionType: string
    ) {
        this.campaignKey = campaignKey
        this.name = name
        this.description = description
        this.providerName = providerName
        this.ipLimitEnabled = ipLimitEnabled
        this.rateLimitEnabled = rateLimitEnabled
        this.recaptchaEnabled = recaptchaEnabled
        this.externalConfirmationRequired = externalConfirmationRequired
        this.supportedRedemptionType = redemptionType
    }
}
