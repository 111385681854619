export class LeaderboardState {
    public loading: boolean
    public accessToken?: string
    public existsForPromotion: boolean
    public campaignKey: string
    public promotionKey: string
    public participants: number

    constructor() {
        this.loading = false
        this.accessToken = undefined
        this.existsForPromotion = false
        this.campaignKey = ''
        this.promotionKey = ''
        this.participants = 0
    }
}
