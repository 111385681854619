





import Vue from 'vue'

export default Vue.extend({
    mounted() {
        this.$store.dispatch('clearError')
    },
    computed: {
        errorText(): string {
            return `Error occured: ${this.$store.state.err.latest}`
        },
    },
})
