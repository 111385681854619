









import Vue from 'vue'
import LogoutButton from './LogoutButton.vue'

export default Vue.extend({
    components: {
        LogoutButton,
    },
    methods: {
        navigateToOverview() {
            if (!this.$route.fullPath.startsWith('/overview')) {
                this.$router.push('/overview')
            }
        },
    },
})
