




















































import Vue from 'vue'

export default Vue.extend({
    name: 'LeaderboardBrazeComponent',
    data: function() {
        return {
            brazeValidation: {
                regex:
                    '^[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}$',
                errorMessage: 'Invalid Braze ID pattern.',
            },
            numberValidation: {
                regex: '^[1-9]{1}[0-9]*$',
                errorMessage: 'Invalid number',
            },
            id: undefined,
            from: undefined,
            to: undefined,
            delay: undefined,
        }
    },
    props: ['campaignKey', 'promotionKey'],
    methods: {
        brazeCampaignIdChanged: function(event: CustomEvent) {
            this.id = event.detail == '' ? undefined : event.detail
        },
        brazeFromChanged: function(event: CustomEvent) {
            this.from = event.detail == '' ? undefined : event.detail
        },
        brazeToChanged: function(event: CustomEvent) {
            this.to = event.detail == '' ? undefined : event.detail
        },
        brazeDelayChanged: function(event: CustomEvent) {
            this.delay = event.detail == '' ? undefined : event.detail
        },
        sendBrazeCampaign() {
            var body = {
                campaignKey: this.campaignKey,
                promotionKey: this.promotionKey,
                brazeId: this.id,
                rankFrom: this.from,
                rankTo: this.to,
                delay: this.delay,
            }
            this.$store.dispatch('triggerBrazeCampaign', body)
        },
    },
})
