












import Vue from 'vue'
import { UserInteraction } from '@/models/userInteraction.model'

export default Vue.extend({
    name: 'UserInteractionItem',
    props: {
        interaction: Object as () => UserInteraction,
    },
})
