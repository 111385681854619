



































import Vue from 'vue'
import ParticipationItem from './ParticipationItem.vue'
import { Participation } from '@/models/participation.model'
import { Campaign } from '@/models/campaign.model'

export default Vue.extend({
    name: 'ParticipationList',
    components: {
        ParticipationItem,
    },
    data() {
        return {
            campaignKey: '',
        }
    },
    mounted() {
        this.campaignKey = this.$route.params.campaignKey
    },
    computed: {
        campaign(): Campaign {
            return this.$store.getters.getCampaignByKey(this.$data.campaignKey)
        },
        searchByUserResultFound(): number {
            if (this.campaign) {
                if (
                    this.campaign.participationSearchByUimUserResult &&
                    this.campaign.participationSearchByUimUserResult.length > 0
                )
                    return 1
                if (
                    this.campaign.participationSearchByUimUserResult &&
                    this.campaign.participationSearchByUimUserResult.length == 0
                )
                    return 0
            }
            return -1
        },
        searchByCodeResultFound(): number {
            if (this.campaign) {
                if (
                    this.campaign.participationSearchByCodeResult &&
                    this.campaign.participationSearchByCodeResult.length > 0
                )
                    return 1
                if (
                    this.campaign.participationSearchByCodeResult &&
                    this.campaign.participationSearchByCodeResult.length == 0
                )
                    return 0
            }
            return -1
        },
        searchResultFound(): number {
            if (
                this.searchByCodeResultFound == 1 ||
                this.searchByUserResultFound == 1
            )
                return 1
            if (
                this.searchByCodeResultFound == -1 &&
                this.searchByUserResultFound == -1
            )
                return -1
            return 0
        },
        participationList(): Participation[] {
            if (this.searchByUserResultFound) {
                return this.campaign.participationSearchByUimUserResult
            } else if (this.searchByCodeResultFound) {
                return this.campaign.participationSearchByCodeResult
            }
            return []
        },
    },
    methods: {
        participationKey: function(participation: Participation): string {
            return participation.uimUserId.concat('-', participation.code)
        },
    },
})
