import { render, staticRenderFns } from "./RedemptionsExportComponent.vue?vue&type=template&id=6f240d33&scoped=true&"
import script from "./RedemptionsExportComponent.vue?vue&type=script&lang=ts&"
export * from "./RedemptionsExportComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f240d33",
  null
  
)

export default component.exports