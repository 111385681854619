import { ROUTING, AUTH_STORAGE } from './constants'

export namespace StorageUtils {
    export const setRedirect = (fullPath: any) => {
        if (fullPath) {
            localStorage.setItem(ROUTING.ROUTE_STORAGE_KEY, fullPath)
        }
    }

    export const getRedirect = (): string => {
        const destinationFromStorage = localStorage.getItem(
            ROUTING.ROUTE_STORAGE_KEY
        )
        localStorage.removeItem(ROUTING.ROUTE_STORAGE_KEY)
        return destinationFromStorage
            ? destinationFromStorage
            : ROUTING.DEFAULT_PATH
    }

    export const cleanseAuth = () => {
        localStorage.removeItem(AUTH_STORAGE.AUTH_ZERO_LOADING)
        localStorage.removeItem(AUTH_STORAGE.EXPIRES_AT)
        localStorage.removeItem(AUTH_STORAGE.ACCESS_TOKEN)
    }

    export const setAuthLoadingInProgress = () => {
        localStorage.setItem(AUTH_STORAGE.AUTH_ZERO_LOADING, '420')
    }
    export const setAuthLoadingDone = () => {
        localStorage.removeItem(AUTH_STORAGE.AUTH_ZERO_LOADING)
    }
    export const setAuthAccessToken = (accessToken: string) => {
        localStorage.setItem(AUTH_STORAGE.ACCESS_TOKEN, accessToken)
    }
    export const setAuthAccessTokenExpiresAt = (expiresAt: number) => {
        localStorage.setItem(AUTH_STORAGE.EXPIRES_AT, JSON.stringify(expiresAt))
    }
    export const getAuthAccessTokenExpiresAt = (): number =>
        JSON.parse(localStorage.getItem(AUTH_STORAGE.EXPIRES_AT) || '-1')
    export const getAuthAccessToken = (): string | undefined =>
        localStorage.getItem(AUTH_STORAGE.ACCESS_TOKEN) || undefined
    export const isAuthLoading = (): boolean =>
        !!localStorage.getItem(AUTH_STORAGE.AUTH_ZERO_LOADING)
}
