




















































import Vue from 'vue'
import { PromotionStatistics } from '../models/promotionStatistics.model'

export default Vue.extend({
    props: {
        stats: Object as () => PromotionStatistics,
    },
    methods: {
        deletePromotion() {
            if (!this.deleteButtonDisabled) {
                let c = confirm('Really delete promotion?')
                if (c) {
                    let payload = {
                        promotionKey: this.stats.promotionKey,
                        campaignKey: this.$route.params.campaignKey,
                    }
                    this.$store.dispatch('deletePromotion', payload)
                }
            }
        },
    },
    computed: {
        unconfirmedVisible() {
            const stats = this.stats as PromotionStatistics
            return stats.unconfirmedPossible
        },
        deleteButtonDisabled(): boolean {
            const stats = this.stats as PromotionStatistics
            return stats.redemptionCount != 0
        },
        updatePromotion() {
            const campaignKey = this.$route.params.campaignKey as string
            const promotionKey = this.stats.promotionKey as string
            return `/promotion_update/${campaignKey}/${promotionKey}`
        },
        detailsForPromotion() {
            const campaignKey = this.$route.params.campaignKey as string
            const promotionKey = this.stats.promotionKey as string
            return `/promotion_details/${campaignKey}/${promotionKey}`
        },
    },
})
