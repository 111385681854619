










































import Vue from 'vue'
import { CreateManagementUser } from '@/models/createmanagementuser.model'
import { Campaign } from '@/models/campaign.model'

export default Vue.extend({
    name: 'ManagementUserCreator',
    data() {
        return {
            overviewLink: '/',
            userName: '',
            campaignKeys: Array.of<string>(),
        }
    },
    methods: {
        saveUser() {
            this.userName = this.userName.trim()
            if (!this.userName || 0 === this.userName.length) {
                this.$store.commit('setError', 'Username is required')
                return
            }
            this.$store
                .dispatch(
                    'createUser',
                    new CreateManagementUser(this.userName, this.campaignKeys)
                )
                .then(() => {
                    this.$router.push('/')
                    this.$store.commit('setInfo', 'Created User')
                })
        },
        inputChanged: function(event: CustomEvent<string>) {
            this.userName = event.detail
        },
        onSelectChanged: function(
            event: CustomEvent<Boolean>,
            campaign: Campaign
        ) {
            if (event.detail === true) {
                this.campaignKeys.push(campaign.campaignKey)
            } else {
                this.campaignKeys = this.campaignKeys.filter(function(item) {
                    return item !== campaign.campaignKey
                })
            }
        },
    },
    computed: {
        campaignList(): Campaign[] {
            return this.$store.state.data.campaigns.list
        },
    },
})
