export class createPromotion {
    promotionKey: string
    name: string
    description: string
    campaignKey: string
    maxRedemptionsPerUser: number
    validFrom: string
    validUntil: string

    constructor(
        promotionKey: string,
        name: string,
        description: string,
        campaignKey: string,
        maxRedemptionsPerUser: number,
        validFrom: string,
        validUntil: string
    ) {
        this.promotionKey = promotionKey
        this.name = name
        this.description = description
        this.campaignKey = campaignKey
        this.maxRedemptionsPerUser = maxRedemptionsPerUser
        this.validFrom = validFrom
        this.validUntil = validUntil
    }
}
