





































































import Vue from 'vue'
import { createPromotion } from '@/models/createPromotion.model'
import { CampaignStatistics } from '../models/campaignStatistics.model'
import { PromotionStatistics } from '@/models/promotionStatistics.model'

export default Vue.extend({
    name: 'ManagementPromotionUpdater',
    props: {
        stats: Object as () => PromotionStatistics,
    },
    data() {
        return {
            overviewLink: '/',
            promotionKey: '',
            name: '',
            description: '',
            campaignKey: '',
            maxRedemptionsPerUser: 1,
            validFrom: '',
            validUntil: '',
            scope: '',
            isError: false,
            errorMsg: '',
        }
    },
    mounted() {
        const stats = this.promotionStats

        this.campaignKey = this.$route.params.campaignKey
        this.promotionKey = this.$route.params.promotionKey
        this.name = stats.promotionName
        this.description = stats.description
        this.maxRedemptionsPerUser = stats.maxRedemptionsPerUser
        this.scope = stats.validFrom + '/' + stats.validUntil
        this.validUntil = stats.validUntil
        this.validFrom = stats.validFrom
    },
    methods: {
        savePromotion() {
            this.isError = false
            if (!this.name || 0 === this.name.length) {
                this.errorMsg = 'Name is required'
                this.isError = true
                return
            }
            if (!this.description || 0 === this.description.length) {
                this.errorMsg = 'Description is required'
                this.isError = true
                return
            }
            if (!this.scope || 0 === this.scope.length) {
                this.errorMsg = 'Scope is required'
                this.isError = true
                return
            }
            let promotion = new createPromotion(
                this.promotionKey,
                this.name,
                this.description,
                this.campaignKey,
                this.maxRedemptionsPerUser,
                this.validFrom,
                this.validUntil
            )
            this.$store.dispatch('updatePromotion', promotion).then(() => {
                this.$store.commit('setInfo', 'Promotion updated')
                this.goBack()
                this.$store.dispatch('fetchStatsForCampaign', this.campaignKey)
            })
        },
        nameChange: function(event: CustomEvent<string>) {
            this.name = event.detail
        },
        descriptionChange: function(event: CustomEvent<string>) {
            this.description = event.detail
        },
        maxRedemptionsChange: function(event: CustomEvent<number>) {
            this.maxRedemptionsPerUser = event.detail
        },
        onScopeSelected: function(event: CustomEvent<string>) {
            this.scope = event.detail
            const tmp = this.scope.split('/', 2)
            this.validFrom = tmp[0]
            this.validUntil = tmp[1]
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
        },
    },
    computed: {
        promotionStats() {
            const campaignKey = this.$route.params.campaignKey
            const campaignStatistics = this.$store.getters.getStatisticsForCampaign(
                campaignKey
            ) as CampaignStatistics
            const stats = campaignStatistics.promotionStats.filter(
                value => this.$route.params.promotionKey === value.promotionKey
            )
            return stats[0] as PromotionStatistics
        },
    },
})
