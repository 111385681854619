import { AuthHeaders } from '@/state/auth/auth.state'
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js'
import { RootState } from '@/state/store'

const oktaConfig: OktaAuthOptions = {
    responseType: 'token',
    scopes: ['openid', 'email', 'profile', 'rbshortname', 'groups'],
    issuer: 'https://auth.redbull.com/oauth2/default',
    clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
    redirectUri: process.env.VUE_APP_OKTA_CALLBACK,
}

const oktaWebAuthInstance: OktaAuth = new OktaAuth(oktaConfig)

export interface ParsedAuthHash {
    expiresAt: number
    accessToken: string
}

export class WebAuthOperations {
    public static checkValidityFor(
        expiresAtMillis: number,
        token: string | undefined
    ): boolean {
        return !!token && expiresAtMillis > new Date().getTime()
    }

    public static authorize(): void {
        oktaWebAuthInstance.signInWithRedirect()
    }

    public static logout(): void {
        const logoutPath = (): string => {
            const baseUrl: string = process.env.VUE_APP_OKTA_CALLBACK || ''
            if (baseUrl.endsWith('/')) {
                return `${baseUrl}logout`
            }
            return `${baseUrl}/logout`
        }
        oktaWebAuthInstance.signOut({
            postLogoutRedirectUri: logoutPath(),
        })
    }

    public static handleAuthentication(): Promise<ParsedAuthHash> {
        return new Promise((resolve, reject) => {
            if (oktaWebAuthInstance.token.isLoginRedirect()) {
                oktaWebAuthInstance.token
                    .parseFromUrl()
                    .then((tokenResponse: any) => {
                        const tokens = tokenResponse.tokens
                        oktaWebAuthInstance.tokenManager.setTokens(
                            tokenResponse.tokens
                        )

                        const response = {
                            expiresAt: tokens.accessToken.expiresAt * 1000 || 0,
                            accessToken: tokens.accessToken.accessToken || '',
                        }
                        resolve(response)
                    })
                    .catch((err: any) => {
                        reject('Error while parsing the url: ' + err)
                    })
            }
        })
    }

    public static setSession(accessToken?: string): Promise<AuthHeaders> {
        return new Promise((resolve, reject) => {
            if (accessToken) {
                const headers: AuthHeaders = {
                    Authorization: `Bearer ${accessToken}`,
                }
                resolve(headers)
            } else {
                reject()
            }
        })
    }
    public static hasValidHeaders(state: RootState) {
        return !!state.auth.headers && !!state.auth.headers.Authorization
    }

    public static hasValidToken(state: RootState) {
        return this.checkValidityFor(
            state.auth.expiresAt,
            state.auth.accessToken
        )
    }

    public static isUserAuthenticated(state: RootState) {
        const hasValidTokens = this.hasValidToken(state)
        const hasValidHeaders = this.hasValidHeaders(state)

        return hasValidTokens && hasValidHeaders
    }
}
