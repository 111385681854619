










































import JsonCSV from 'vue-json-csv'
import Vue from 'vue'

Vue.component('downloadCsv', JsonCSV)

export default Vue.extend({
    name: 'LeaderboardRankingsComponent',
    data: function() {
        return {
            minimumNumberOfRedemptions: 2,
            limit: 10,
            entries: undefined,
            defaultColumns: [
                {
                    title: 'UserId',
                    field: 'uimUserId',
                },
                {
                    title: 'Number of redemptions',
                    field: 'redemptionCount',
                },
            ],
        }
    },
    props: ['campaignKey', 'promotionKey'],
    methods: {
        refreshRedemptions: function() {
            this.$store
                .dispatch('fetchRedemptions', {
                    campaignKey: this.campaignKey,
                    promotionKey: this.promotionKey,
                    minimumNumberOfRedemptions: this.minimumNumberOfRedemptions,
                    limit: this.limit,
                })
                .then(result => (this.entries = result))
        },
        csvFileName: function(): String {
            // @ts-ignore
            return (
                'redemptions-' +
                this.campaignKey +
                '-' +
                this.promotionKey +
                '-' +
                Date.now() +
                '.csv'
            )
        },
        minimumNumberOfRedemptionsChanged: function(
            event: CustomEvent<number>
        ) {
            this.minimumNumberOfRedemptions = event.detail
        },
        limitChanged: function(event: CustomEvent<number>) {
            this.limit = event.detail
        },
    },
    computed: {
        selectColumns() {
            return this.$data.defaultColumns
        },
    },
})
