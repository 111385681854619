







import Vue from 'vue'
export default Vue.extend({
    methods: {
        handleLogout(): void {
            this.$store.dispatch('logout')
        },
    },
})
