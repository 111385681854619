



































import Vue from 'vue'
import UserInteractionItem from './UserInteractionItem.vue'
import { UserInteraction } from '@/models/userInteraction.model'
import { UserInteractionSearch } from '@/models/userInteractionSearch'

export default Vue.extend({
    name: 'UserInteractionList',
    components: {
        UserInteractionItem,
    },
    computed: {
        userInteraction(): UserInteractionSearch {
            return this.$store.getters.getUserInteractionSearch()
        },
        interactionList(): UserInteraction[] | undefined {
            return this.userInteraction.interactions
        },
        searchResultFound(): number {
            if (this.userInteraction.searchTerm.length == 0) return -1
            if (this.interactionList && this.interactionList.length > 0)
                return 1
            if (this.interactionList && this.interactionList.length == 0)
                return 0
            return -1
        },
    },
    methods: {
        interactionKey: function(userInteraction: UserInteraction) {
            return userInteraction.code
                .concat('-', userInteraction.uimUserId)
                .concat('-', userInteraction.createdAt)
        },
    },
})
