import { ActionTree, Commit, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/state/store'
import { LeaderboardStatisticsModel } from '@/models/leaderboardStatistics.model'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { decode } from 'jsonwebtoken'
import { HeaderUtils } from '@/state/headers.utils'

export const leaderboardGetters: GetterTree<RootState, RootState> = {
    getParticipantCount: (state): number => {
        return state.leaderboard.participants
    },
    isLeaderboardLoading: (state): boolean => {
        return state.leaderboard.loading
    },
    existsLeaderboard: state => {
        return (campaignKey: string, promotionKey: string): boolean => {
            if (
                state.leaderboard.campaignKey == campaignKey &&
                state.leaderboard.promotionKey == promotionKey
            )
                return state.leaderboard.existsForPromotion
            else return false
        }
    },
}

export const leaderboardMutations: MutationTree<RootState> = {
    setParticipantCount: (state: RootState, numberOfParticipants) => {
        state.leaderboard.participants = numberOfParticipants
    },
    setLeaderboardLoading: (state: RootState, isLoading) => {
        state.leaderboard.loading = isLoading
    },
    setLeaderboardExistsForPromotion: (
        state: RootState,
        { campaignKey, promotionKey, exists }
    ) => {
        state.leaderboard.existsForPromotion = exists
        state.leaderboard.campaignKey = campaignKey
        state.leaderboard.promotionKey = promotionKey
    },
    updateCampaignAccessToken: (state: RootState, { token }) => {
        state.leaderboard.accessToken = token
    },
}

export const leaderboardActions: ActionTree<RootState, RootState> = {
    fetchLeaderboardStatistics: (
        { commit, state },
        { campaignKey, promotionKey }
    ) => {
        commit('setLeaderboardLoading', true)
        return new Promise<LeaderboardStatisticsModel>((resolve, reject) => {
            fetchAccessToken(commit, state, campaignKey).then(accessToken => {
                const headers = {
                    ...state.auth.headers,
                    'ACCESS-TOKEN': accessToken,
                }
                axios
                    .get(
                        process.env.VUE_APP_CODE_LEADERBOARD_BASE_URL +
                            '/api/mgmt/campaign/' +
                            campaignKey +
                            '/leaderboard/' +
                            promotionKey,
                        {
                            headers: HeaderUtils.constructHeadersMap(
                                state.auth.headers
                            ),
                        }
                    )
                    .then((result: AxiosResponse) => {
                        commit(
                            'setParticipantCount',
                            result.data.numberOfParticipants
                        )
                        commit('setLeaderboardExistsForPromotion', {
                            campaignKey,
                            promotionKey,
                            exists: true,
                        })
                        resolve()
                    })
                    .catch((err: AxiosError) => {
                        commit('setLeaderboardExistsForPromotion', {
                            campaignKey,
                            promotionKey,
                            exists: false,
                        })
                        reject()
                    })
                    .finally(() => {
                        commit('setLeaderboardLoading', false)
                    })
            })
        })
    },
    triggerBrazeCampaign: (
        { commit, state },
        { campaignKey, promotionKey, brazeId, rankFrom, rankTo, delay }
    ) => {
        new Promise((resolve, reject) => {
            fetchAccessToken(commit, state, campaignKey).then(accessToken => {
                const headers = {
                    ...state.auth.headers,
                    'ACCESS-TOKEN': accessToken,
                }
                var body = {
                    brazeCampaignKey: brazeId,
                    fromPosition: rankFrom,
                    toPosition: rankTo,
                    delay: delay,
                }

                axios
                    .post(
                        process.env.VUE_APP_CODE_LEADERBOARD_BASE_URL +
                            '/api/mgmt/campaign/' +
                            campaignKey +
                            '/leaderboard/' +
                            promotionKey +
                            '/braze/fullfil',
                        body,
                        {
                            headers: HeaderUtils.constructHeadersMap(
                                state.auth.headers
                            ),
                        }
                    )
                    .then(() => {
                        commit('setInfo', 'Braze Campaign sent.')
                    })
                    .catch((err: AxiosError) => {
                        if (err.response !== undefined)
                            commit(
                                'setError',
                                'Failed to sent Braze Campaign - ' +
                                    err.response.data
                            )
                        else
                            commit(
                                'setError',
                                'Failed to sent Braze Campaign - ' + err.message
                            )
                    })
            })
        })
    },
    triggerRewardCeremony: (
        { commit, state },
        { campaignKey, promotionKey, rankFrom, rankTo, skuId }
    ) => {
        new Promise(() => {
            fetchAccessToken(commit, state, campaignKey).then(
                (accessToken: String) => {
                    const headers = {
                        ...state.auth.headers,
                        'ACCESS-TOKEN': accessToken,
                    }
                    axios
                        .post(
                            process.env.VUE_APP_CODE_LEADERBOARD_BASE_URL +
                                `/api/mgmt/campaign/${campaignKey}/leaderboard/${promotionKey}/${rankFrom}/${rankTo}/skuid/${skuId}`,
                            undefined,
                            {
                                headers: HeaderUtils.constructHeadersMap(
                                    state.auth.headers
                                ),
                            }
                        )
                        .then(() => {
                            commit('setInfo', 'Your rewards are being sent.')
                        })
                        .catch((err: AxiosError) => {
                            if (err.response !== undefined)
                                commit(
                                    'setError',
                                    'Failed to sent rewards - ' +
                                        err.response.data
                                )
                            else
                                commit(
                                    'setError',
                                    'Failed to sent rewards - ' + err.message
                                )
                        })
                }
            )
        })
    },
    fetchPromotionRankings: (
        { commit, state },
        { campaignKey, promotionKey, limit }
    ) => {
        return new Promise((resolve, reject) => {
            fetchAccessToken(commit, state, campaignKey).then(
                (accessToken: String) => {
                    const headers = {
                        ...state.auth.headers,
                        'ACCESS-TOKEN': accessToken,
                    }
                    axios
                        .get(
                            process.env.VUE_APP_CODE_LEADERBOARD_BASE_URL +
                                `/api/mgmt/campaign/${campaignKey}/leaderboard/${promotionKey}/rankings/${limit}`,
                            {
                                headers: HeaderUtils.constructHeadersMap(
                                    state.auth.headers
                                ),
                            }
                        )
                        .then((result: AxiosResponse) => {
                            resolve(result.data)
                        })
                        .catch((err: AxiosError) => {
                            if (err.response !== undefined)
                                commit(
                                    'setError',
                                    'Failed to load rankings - ' +
                                        err.response.data
                                )
                            else
                                commit(
                                    'setError',
                                    'Failed to load rankings - ' + err.message
                                )
                            reject()
                        })
                }
            )
        })
    },
}

function fetchAccessToken(
    commit: Commit,
    state: RootState,
    campaignKey: string
): Promise<String> {
    if (
        state.leaderboard.accessToken !== undefined &&
        state.leaderboard.accessToken !== null &&
        state.leaderboard.campaignKey === campaignKey
    ) {
        const data = decode(state.leaderboard.accessToken)
        // @ts-ignore
        if (data !== null && data.exp * 1000 > Date.now().valueOf()) {
            return Promise.resolve(state.leaderboard.accessToken)
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .get(
                process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                    `/mgmt/campaign/${campaignKey}/token`,
                { headers: HeaderUtils.constructHeadersMap(state.auth.headers) }
            )
            .then(response => {
                commit('updateCampaignAccessToken', {
                    token: response.data.campaignToken,
                })
                resolve(response.data.campaignToken)
            })
            .catch((err: AxiosError) => {
                commit(
                    'setError',
                    `Failed to get access for campaign. [${campaignKey}]`
                )
                reject()
            })
    })
}
