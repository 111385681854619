















import Vue from 'vue'
import UserInteractionList from '@/components/UserInteractionList.vue'

export default Vue.extend({
    name: 'UserInteractionSearch',
    components: {
        UserInteractionList,
    },
    data() {
        return {
            timeout: undefined,
            searchParam: '',
        }
    },
    mounted() {
        this.searchParam = this.userInteraction.searchTerm
    },
    computed: {
        userInteraction() {
            return this.$store.getters.getUserInteractionSearch()
        },
    },
    methods: {
        triggerSearch: function(searchParam: string) {
            if (searchParam !== this.userInteraction.searchTerm) {
                this.$store.dispatch('searchForUserInteraction', searchParam)
            }
        },
        searchChanged: function(event: CustomEvent<string>) {
            if (this.timeout) clearTimeout(this.timeout)
            // @ts-ignore
            this.timeout = setTimeout(() => {
                this.triggerSearch(event.detail)
            }, 500)
        },
    },
})
