import '@/styles/base.scss'
import Vue from 'vue'
import App from './App.vue'
import { appStore } from './state/store'
import { router } from './router/router'
import {
    applyPolyfills,
    defineCustomElements,
} from '@rbmh-design-system/web-components/loader'

Vue.config.productionTip = false
Vue.config.ignoredElements = [/test-\w*/, /rb-\w*/]

applyPolyfills().then(() =>
    defineCustomElements(window).then(() =>
        new Vue({
            store: appStore,
            router: router,
            render: h => h(App),
        }).$mount('#app')
    )
)
