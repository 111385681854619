




















import Vue from 'vue'
import { ManagementUser } from '@/models/managementuser.model'

export default Vue.extend({
    name: 'ManagementUserItem',
    props: {
        user: Object as () => ManagementUser,
    },
    methods: {
        deleteUser() {
            let c = confirm('Really delete user?')
            if (c) {
                this.$store.dispatch('deleteManagementUser', this.user.userName)
            }
        },
    },
    computed: {
        userName(): string {
            const user = this.user as ManagementUser
            return user.userName
        },
        numberOfCampaigns(): number {
            const user = this.user as ManagementUser
            return user.campaigns.length
        },
        editUserLink() {
            const user = this.user as ManagementUser
            return `/user/${user.id}`
        },
    },
})
