import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { RootState } from '../store'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Campaign } from '@/models/campaign.model'
import { ParticipationSearchParams } from '@/models/participationsearch.model'
import { CampaignAccess } from '@/models/campaignaccess.model'
import { ManagementUser } from '@/models/managementuser.model'
import { UserInfo } from '@/models/userInfo.model'
import { PromotionStatistics } from '@/models/promotionStatistics.model'
import { HeaderUtils } from '@/state/headers.utils'
import constructHeadersMap = HeaderUtils.constructHeadersMap

/* prettier-ignore */
function findCampaignByKey(campaigns: Campaign[] | undefined, key: string): any | undefined {
    if(campaigns){
        const campaign = campaigns.find(elem => elem.campaignKey === key)
        if(campaign){
            return {campaign, index: campaigns.indexOf(campaign)}
        }
    }
    return undefined
}

/* prettier-unignore */
export const dataGetters: GetterTree<RootState, RootState> = {
    getStatisticsForCampaign(state) {
        return (campaignKey: string) => {
            if (state.data.campaigns.list) {
                const c = findCampaignByKey(
                    state.data.campaigns.list,
                    campaignKey
                )
                if (c !== undefined && c.campaign !== undefined) {
                    return c.campaign.statistics
                }
            }
            return undefined
        }
    },
    getStatisticsForPromotion(state) {
        return (campaignKey: string, promotionKey: string) => {
            if (state.data.campaigns.list) {
                const c = findCampaignByKey(
                    state.data.campaigns.list,
                    campaignKey
                )
                if (c !== undefined && c.campaign !== undefined) {
                    return c.campaign.statistics.promotionStats.find(
                        (p: PromotionStatistics) =>
                            p.promotionKey === promotionKey
                    ) as PromotionStatistics
                }
            }
            return undefined
        }
    },
    getCampaignByKey(state) {
        return (campaignKey: string) => {
            if (state.data.campaigns.list) {
                const c = findCampaignByKey(
                    state.data.campaigns.list,
                    campaignKey
                )
                if (c !== undefined && c.campaign !== undefined) {
                    return c.campaign
                }
            }
            return undefined
        }
    },
    getUserInteractionSearch(state) {
        return () => {
            return state.data.userInteractionSearch
        }
    },
    getUserById(state) {
        return (userId: string) => {
            if (state.data.managementUsers.list) {
                return state.data.managementUsers.list.find(
                    elem => elem.id.toString() === userId.toString()
                )
            }
            return undefined
        }
    },
    getActiveOverviewTab(state): string {
        return state.data.activeOverviewTab
    },
    getUserInfo(state): UserInfo {
        return state.data.userInfo
    },
}

export const dataMutations: MutationTree<RootState> = {
    setCampaigns: (state: RootState, list) => {
        state.data = { ...state.data, campaigns: { list, isLoading: false } }
    },
    setCampaignsToLoading: (state: RootState) => {
        state.data.campaigns = { ...state.data.campaigns, isLoading: true }
    },
    setUserInteractionSearchTerm: (state: RootState, term) => {
        state.data.userInteractionSearch = {
            ...state.data.userInteractionSearch,
            searchTerm: term,
        }
    },
    setStatsForCampaign: (state: RootState, { data, campaignKey }) => {
        const campaign = findCampaignByKey(
            state.data.campaigns.list,
            campaignKey
        )

        if (campaign && state.data.campaigns.list) {
            Vue.set(
                state.data.campaigns.list,
                campaign.index,
                campaign.campaign
            )
            Vue.set(campaign.campaign, 'statistics', data)
        }
    },
    setSearchResultForCampaign: (
        state: RootState,
        { data, campaignKey, stateVariableName }
    ) => {
        const campaign = findCampaignByKey(
            state.data.campaigns.list,
            campaignKey
        )
        if (campaign) {
            Vue.set(campaign.campaign, stateVariableName, data)
        }
    },
    setUserInteractionSearchResult: (state: RootState, data) => {
        Vue.set(state.data.userInteractionSearch, 'interactions', data)
    },
    setLoadingStats: (state: RootState, campaignKey) => {
        const campaign = findCampaignByKey(
            state.data.campaigns.list,
            campaignKey
        )
        if (campaign) {
            campaign.loading = true
        }
    },
    setManagementUsers: (state: RootState, data) => {
        Vue.set(state.data, 'managementUsers', { list: data, isLoading: false })
    },
    setLoadingManagementUsers: (state: RootState) => {
        Vue.set(state.data, 'managementUsers', {
            list: undefined,
            isLoading: true,
        })
    },
    updateManagementUser: (state: RootState, data) => {
        const updatedUser = data as ManagementUser
        if (state.data.managementUsers.list) {
            Vue.set(
                state.data.managementUsers,
                'list',
                state.data.managementUsers.list.map(elem =>
                    elem.id.toString() === data.id.toString()
                        ? updatedUser
                        : elem
                )
            )
        }
    },
    setActiveOverviewTab: (state: RootState, data) => {
        const activeTab = data as string
        Vue.set(state.data, 'activeOverviewTab', activeTab)
    },
    setUserInfo: (state: RootState, data) => {
        const userInfo = data as UserInfo
        Vue.set(state.data, 'userInfo', userInfo)
    },
}

export const dataActions: ActionTree<RootState, RootState> = {
    retrieveStatsForCampaign: ({ dispatch, getters }, campaignKey: string) => {
        dispatch('retrieveCampaigns').then(() => {
            if (!getters.getStatisticsForCampaign(campaignKey) && campaignKey) {
                dispatch('fetchStatsForCampaign', campaignKey)
            }
        })
    },
    retrieveCampaigns: ({ dispatch, state }) => {
        if (state.data.campaigns.list === undefined) {
            return dispatch('fetchCampaigns')
        }
        return Promise.resolve()
    },
    retrieveCampaign: ({ state }, campaignKey: string) => {
        return findCampaignByKey(state.data.campaigns.list, campaignKey)
    },
    fetchCampaigns: ({ commit, state }) => {
        return axios
            .get(
                process.env.VUE_APP_CODE_REDEMPTION_BASE_URL + '/mgmt/campaign',
                {
                    headers: HeaderUtils.constructHeadersMap(
                        state.auth.headers
                    ),
                }
            )
            .then((result: AxiosResponse) => {
                commit('setCampaigns', result.data)
            })
            .catch((err: AxiosError) => {
                commit('setError', err.message)
                commit('setCampaigns', undefined)
                Promise.reject(err)
            })
    },
    fetchStatsForCampaign: ({ commit, state }, campaignKey: string) => {
        commit('setLoadingStats', campaignKey)
        axios
            .get(
                process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                    '/mgmt/campaign/stats/' +
                    campaignKey,
                {
                    headers: HeaderUtils.constructHeadersMap(
                        state.auth.headers
                    ),
                }
            )
            .then((result: AxiosResponse) => {
                const { data } = result
                commit('setStatsForCampaign', {
                    data,
                    campaignKey,
                })
            })
            .catch((err: AxiosError) => {
                commit('setError', err.message)
                commit('setStatsForCampaign', { undefined, campaignKey })
            })
    },
    fetchRedemptions: (
        { commit, state },
        { campaignKey, promotionKey, minimumNumberOfRedemptions, limit }
    ) => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                        '/mgmt/campaign/' +
                        campaignKey +
                        '/promotion/' +
                        promotionKey +
                        '/redemptions?minimumRedemptions=' +
                        minimumNumberOfRedemptions +
                        '&count=' +
                        limit,
                    {
                        headers: HeaderUtils.constructHeadersMap(
                            state.auth.headers
                        ),
                    }
                )
                .then((result: AxiosResponse) => {
                    resolve(result.data)
                })
                .catch((err: AxiosError) => {
                    if (err.response !== undefined)
                        commit(
                            'setError',
                            'Failed to fetch redemptions - ' + err.response.data
                        )
                    else
                        commit(
                            'setError',
                            'Failed to fetch redemptions - ' + err.message
                        )
                    reject()
                })
        })
    },
    searchForUserInteraction: ({ commit, state }, uimUserId: string) => {
        commit('setUserInteractionSearchTerm', uimUserId)
        if (uimUserId) {
            axios
                .get(
                    process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                        '/mgmt/interaction/' +
                        uimUserId,
                    {
                        headers: HeaderUtils.constructHeadersMap(
                            state.auth.headers
                        ),
                    }
                )
                .then((result: AxiosResponse) => {
                    const { data } = result
                    commit('setUserInteractionSearchResult', data)
                })
                .catch((err: AxiosError) => {
                    commit('setError', err.message)
                    commit('setUserInteractionSearchResult', undefined)
                })
        }
    },
    searchForParticipation: (
        { commit, state },
        searchparam: ParticipationSearchParams
    ) => {
        const { campaignKey, searchTerm } = searchparam

        function executeSearch(path: string, stateVariableName: string) {
            axios
                .get(
                    process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                        path +
                        campaignKey +
                        '/' +
                        searchTerm,
                    {
                        headers: HeaderUtils.constructHeadersMap(
                            state.auth.headers
                        ),
                    }
                )
                .then((result: AxiosResponse) => {
                    const { data } = result
                    commit('setSearchResultForCampaign', {
                        data,
                        campaignKey,
                        stateVariableName,
                    })
                })
                .catch((err: AxiosError) => {
                    commit('setSearchResultForCampaign', {
                        undefined,
                        campaignKey,
                        stateVariableName,
                    })
                })
        }

        executeSearch(
            '/mgmt/campaign/participation/user/',
            'participationSearchByUimUserResult'
        )
        executeSearch(
            '/mgmt/campaign/participation/code/',
            'participationSearchByCodeResult'
        )
    },
    fetchManagementUsers: ({ commit, state }) => {
        commit('setLoadingManagementUsers', true)
        axios
            .get(process.env.VUE_APP_CODE_REDEMPTION_BASE_URL + '/mgmt/user', {
                headers: HeaderUtils.constructHeadersMap(state.auth.headers),
            })
            .then((result: AxiosResponse) => {
                commit('setManagementUsers', result.data)
            })
            .catch((err: AxiosError) => {
                commit('setError', err.message)
                commit('setManagementUsers', undefined)
            })
    },
    deleteManagementUser: ({ dispatch, commit, state }, userName) => {
        commit('setLoadingManagementUsers', true)
        axios
            .delete(
                `${process.env.VUE_APP_CODE_REDEMPTION_BASE_URL}/mgmt/user/${userName}/delete`,
                {
                    headers: HeaderUtils.constructHeadersMap(
                        state.auth.headers
                    ),
                }
            )
            .then((result: AxiosResponse) => {
                dispatch('fetchManagementUsers')
                commit('setInfo', 'Deleted User ' + userName)
            })
            .catch((err: AxiosError) => {
                commit('setError', err.message)
                dispatch('fetchManagementUsers')
            })
    },
    createUser: ({ commit, state }, userDto) => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                        '/mgmt/user/create',

                    userDto,
                    {
                        headers: HeaderUtils.constructHeadersMap(
                            state.auth.headers
                        ),
                    }
                )
                .then((result: AxiosResponse) => {
                    resolve()
                })
                .catch((err: AxiosError) => {
                    commit(
                        'setError',
                        err.response && err.response.data
                            ? err.response.data
                            : err.message
                    )
                    reject()
                })
        })
    },
    changeAccess: ({ commit, state }, campaignAccess: CampaignAccess) => {
        const headers = state.auth.headers
        const { campaignKey, userName, accessGranted } = campaignAccess
        return new Promise((resolve, reject) => {
            axios
                .post(
                    process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                        '/mgmt/user/' +
                        userName +
                        (accessGranted ? '/addCampaign/' : '/removeCampaign/') +
                        campaignKey,
                    {},
                    {
                        headers: HeaderUtils.constructHeadersMap(
                            state.auth.headers
                        ),
                    }
                )
                .then((result: AxiosResponse) => {
                    commit('updateManagementUser', result.data)
                    resolve()
                })
                .catch((err: AxiosError) => {
                    commit('setError', err.message)
                    reject()
                })
        })
    },
    createCampaign: ({ commit, state }, campaignDto) => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                        '/mgmt/campaign',

                    campaignDto,
                    {
                        headers: HeaderUtils.constructHeadersMap(
                            state.auth.headers
                        ),
                    }
                )
                .then((result: AxiosResponse) => {
                    resolve()
                })
                .catch((err: AxiosError) => {
                    commit(
                        'setError',
                        err.response && err.response.data
                            ? err.response.data
                            : err.message
                    )
                    reject()
                })
        })
    },
    createPromotion: ({ commit, state }, promotionDto) => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                        '/mgmt/promotion',

                    promotionDto,
                    {
                        headers: HeaderUtils.constructHeadersMap(
                            state.auth.headers
                        ),
                    }
                )
                .then((result: AxiosResponse) => {
                    resolve()
                })
                .catch((err: AxiosError) => {
                    commit(
                        'setError',
                        err.response && err.response.data
                            ? err.response.data
                            : err.message
                    )
                    reject()
                })
        })
    },
    deletePromotion: (
        { dispatch, commit, state },
        { promotionKey, campaignKey }
    ) => {
        axios
            .delete(
                `${process.env.VUE_APP_CODE_REDEMPTION_BASE_URL}/mgmt/promotion/${promotionKey}`,
                {
                    headers: HeaderUtils.constructHeadersMap(
                        state.auth.headers
                    ),
                }
            )
            .then((result: AxiosResponse) => {
                dispatch('fetchStatsForCampaign', campaignKey)
                commit('setInfo', 'Deleted Promotion ' + promotionKey)
            })
            .catch((err: AxiosError) => {
                commit('setError', err.message)
                dispatch('fetchStatsForCampaign', campaignKey)
            })
    },
    updatePromotion: ({ commit, state }, promotionDto) => {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                        '/mgmt/promotion',

                    promotionDto,
                    {
                        headers: HeaderUtils.constructHeadersMap(
                            state.auth.headers
                        ),
                    }
                )
                .then((result: AxiosResponse) => {
                    resolve()
                })
                .catch((err: AxiosError) => {
                    commit(
                        'setError',
                        err.response && err.response.data
                            ? err.response.data
                            : err.message
                    )
                    reject()
                })
        })
    },
    uploadCodeFile: ({ commit, state }, codeUploadDto) => {
        return new Promise((resolve, reject) => {
            const body = new FormData()
            body.append('codeFile', codeUploadDto.codeFile)
            if (codeUploadDto.countryCode)
                body.append('country', codeUploadDto.countryCode)
            axios
                .post(
                    process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                        '/mgmt/campaign/' +
                        codeUploadDto.campaignKey +
                        '/code',
                    body,
                    {
                        headers: HeaderUtils.constructHeadersMap(
                            state.auth.headers
                        ),
                    }
                )
                .then((result: AxiosResponse) => {
                    commit('setInfo', 'Codes uploaded for campaign')
                    resolve()
                })
                .catch((err: AxiosError) => {
                    commit(
                        'setError',
                        err.response && err.response.data
                            ? err.response.data
                            : err.message
                    )
                    reject()
                })
        })
    },
    addSingeCodeToCampaign: ({ commit, state }, singleCodeUploadDto) => {
        return new Promise((resolve, reject) => {
            const body = {
                code: singleCodeUploadDto.code,
                countryCode: singleCodeUploadDto.country,
                metadata: singleCodeUploadDto.metadata,
            }
            axios
                .post(
                    process.env.VUE_APP_CODE_REDEMPTION_BASE_URL +
                        '/mgmt/campaign/' +
                        singleCodeUploadDto.campaignKey +
                        '/code/single',
                    body,
                    {
                        headers: HeaderUtils.constructHeadersMap(
                            state.auth.headers
                        ),
                    }
                )
                .then((result: AxiosResponse) => {
                    commit('setInfo', 'Code was added to campaign.')
                    resolve()
                })
                .catch((err: AxiosError) => {
                    commit(
                        'setError',
                        err.response && err.response.data
                            ? err.response.data
                            : err.message
                    )
                    reject()
                })
        })
    },
}
