





import Vue from 'vue'

export default Vue.extend({
    mounted() {
        this.$store.dispatch('clearInfo')
    },
    computed: {
        infoText(): string {
            return `${this.$store.state.info.latest}`
        },
    },
})
