
















































import Vue from 'vue'
import { ManagementUser } from '@/models/managementuser.model'
import { Campaign } from '@/models/campaign.model'

export default Vue.extend({
    name: 'ManagementUserEditor',
    data() {
        return {
            userId: '',
            overviewLink: '/',
        }
    },
    mounted() {
        this.userId = this.$route.params.userId
    },
    computed: {
        user(): ManagementUser {
            return this.$store.getters.getUserById(this.$data.userId)
        },
        userName(): string {
            const user = this.user as ManagementUser
            if (user) {
                return user.userName
            }
            return ''
        },
        campaignList(): Campaign[] {
            return this.$store.state.data.campaigns.list
        },
    },
    methods: {
        hasAccess: function(campaign: Campaign) {
            if (this.user) {
                return this.user.campaigns.find(
                    elem => elem.campaignKey === campaign.campaignKey
                )
            }
            return false
        },
        changeAccess: function(campaign: Campaign, granted: boolean) {
            this.$store.dispatch('changeAccess', {
                campaignKey: campaign.campaignKey,
                userName: this.userName,
                accessGranted: granted,
            })
        },
    },
})
